import { FC, useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';

import { ROUTES } from 'routes';
import { UserRole } from 'interfaces/userInterface';
import { IAppRoute } from 'interfaces/routeInterface';
import { useAppSelector } from 'hooks/useAppSelector';
import { useLogoutUserMutation } from 'redux/api/authApi';
import NestedList from './NestedList';
import SidebarListItem from './ListItem';

const drawerWidth = '28rem';

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(1, 1)
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

const MainSidebar: FC = () => {
  const [open, setOpen] = useState(true);
  const [logout] = useLogoutUserMutation();
  const { user } = useAppSelector(state => state.auth);

  if (!user) return null;

  const handleToggleDrawer = () => {
    setOpen(s => !s);
  };

  const filterRoutes = ({ allowedRoles }: IAppRoute) =>
    allowedRoles?.includes(UserRole[user.role]) ?? true;

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleToggleDrawer}>
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />

      <List>
        {ROUTES.filter(filterRoutes).map(({ label, icon, path, children }) =>
          children ? (
            <NestedList key={label} label={label} sidebarExpanded={open} icon={icon}>
              {children.filter(filterRoutes).map(({ children: nestedChildren, ...routeProps }) =>
                nestedChildren ? (
                  <NestedList
                    key={routeProps.label}
                    label={routeProps.label}
                    sidebarExpanded={open}
                    icon={routeProps.icon}
                    nested
                  >
                    {nestedChildren.filter(filterRoutes).map(r => (
                      <SidebarListItem key={r.label} {...r} sidebarExpanded={open} nested pl={4} />
                    ))}
                  </NestedList>
                ) : (
                  <SidebarListItem
                    key={routeProps.label}
                    {...routeProps}
                    sidebarExpanded={open}
                    nested
                  />
                )
              )}
            </NestedList>
          ) : (
            <SidebarListItem
              key={label}
              label={label}
              icon={icon}
              path={path}
              sidebarExpanded={open}
            />
          )
        )}

        <SidebarListItem label="Logout" icon={LogoutIcon} sidebarExpanded={open} onClick={logout} />
      </List>

      <Typography mt="auto" py={1} alignSelf="center" sx={{ opacity: open ? 1 : 0 }}>
        {process.env.REACT_APP_APP_VERSION}
      </Typography>
    </Drawer>
  );
};

export default MainSidebar;
