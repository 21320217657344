import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import type {
  EndpointBuilder,
  MutationLifecycleApi
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { TagTypes } from 'utils/queryTags';

export const REDUCER_PATH = 'mercuryAPI';

export type RTKMutationType<T> = MutationLifecycleApi<
  T,
  // eslint-disable-next-line @typescript-eslint/ban-types
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
  null,
  typeof REDUCER_PATH
>;

export type RTKBuilder = EndpointBuilder<
  // eslint-disable-next-line @typescript-eslint/ban-types
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
  TagTypes,
  typeof REDUCER_PATH
>;

export type PartialUpdateType<T> = Partial<T> & { id: string };

export type TransformerFunctionType<T, S = T> = (item: T) => S;
