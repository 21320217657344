import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { showStandAloneAlert } from 'contexts/AlertContext';
import { closeDialog } from 'contexts/DialogContext';
import { IRefreshResponse } from 'interfaces/authInterface';
import { Pages } from 'interfaces/general';
import { logout, setCredentials } from 'redux/slices/authSlice';
import { RootState } from 'redux/store';
import { history } from 'utils/history';
import { Storage } from 'utils/Storage';

// We used mutex here to prevent multiple requests when the first request to refresh the token fails
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if ((result.error?.data as { detail: string; code?: string })?.code === 'token_not_valid') {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshToken = Storage.getRefreshToken();

        const refreshResult = refreshToken
          ? await baseQuery(
              {
                url: 'auth/refresh/',
                method: 'POST',
                body: { refresh: refreshToken }
              },
              api,
              extraOptions
            )
          : null;

        if (refreshResult?.data) {
          const { refresh, access } = refreshResult.data as IRefreshResponse;
          api.dispatch(setCredentials({ refreshToken: refresh, accessToken: access }));
          // Retry the initial query
          result = await baseQuery(args, api, extraOptions);
        }

        // Log out the user !
        else {
          api.dispatch(logout());
          history.replace(Pages.LOGIN);
          showStandAloneAlert({
            status: 'error',
            message: 'Your token is not valid anymore. Please login again.',
            duration: 7000
          });
          closeDialog();
        }
      } finally {
        // release must be called once the mutex should be released again
        release();
      }
    }

    // wait until the mutex is available without locking it
    else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export default customFetchBase;
