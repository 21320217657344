import { createApi } from '@reduxjs/toolkit/query/react';

import { showStandAloneAlert } from 'contexts/AlertContext';
import { closeDialog } from 'contexts/DialogContext';
import { GetAllResponse, Params } from 'interfaces/general';
import { queryTags, TagTypes } from 'utils/queryTags';
import customFetchBase from './customFetchBase';
import { REDUCER_PATH, RTKBuilder, TransformerFunctionType } from './types';

export const onSuccessQuery = (doc: string, verb: string) => {
  closeDialog();
  showStandAloneAlert({
    status: 'success',
    message: `${doc} ${verb} successfully.`
  });
};

export type OptionType = {
  name: string;
  id: string;
  cropType?: { name: string };
  cropClass?: { name: string };
  code?: string;
  subvariant?: { cropType: string };
};

export type DocDeleteArgType = {
  id: string;
  tag: TagTypes;
  nestedDoc?: TagTypes;
  nestedDocId?: string;
};

export const baseApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: customFetchBase,
  tagTypes: Object.values(TagTypes),
  endpoints: builder => ({
    deleteDocument: builder.mutation<null, DocDeleteArgType>({
      query: ({ id, tag, nestedDocId, nestedDoc }) => ({
        url: `/${tag}/${id}/${nestedDoc && nestedDocId ? `${nestedDoc}/${nestedDocId}/` : ''}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_, __, arg) => queryTags[arg.tag].list(),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          onSuccessQuery('Document', 'deleted');
        } catch (__) {}
      }
    }),
    getDocuments: builder.query<GetAllResponse<OptionType>, Params & { tag: TagTypes }>({
      query: ({ tag, ...params }) => {
        return {
          url: `/${tag}/`,
          params: { select: 'name', ...params }
        };
      },
      transformResponse: (response: GetAllResponse<OptionType>, _, { select }) => {
        if (select?.includes('cropType') || select?.includes('cropClass')) {
          const newResults = response.results.map(({ id, cropType, cropClass, code }) => ({
            id,
            name: cropClass ? `${cropClass.name} (${code})` : cropType?.name || '***'
          }));

          return { ...response, results: newResults };
        }

        return response;
      },
      providesTags: (_, __, { tag }) => queryTags[tag].list()
    })
  })
});

export class CrudController {
  private builder: RTKBuilder;

  private basePath: string;

  private tag: TagTypes;

  private title: string;

  constructor(builder: RTKBuilder, basePath: string, tag: TagTypes, title = 'Document') {
    this.builder = builder;
    this.basePath = basePath;
    this.tag = tag;
    this.title = title;
  }

  private static getFormDataValue = (val: unknown) => {
    if (typeof val === 'string') return val;

    if (val instanceof FileList) return val[0];

    if (val instanceof File) return val;

    return JSON.stringify(val);
  };

  static generateFormData = (data: Record<string, unknown>) => {
    const formData = new FormData();
    Object.entries(data).forEach(([k, v]) =>
      formData.append(k, CrudController.getFormDataValue(v))
    );
    return formData;
  };

  createDocumentMutation = <T extends Record<string, unknown>>(multipart = false) =>
    this.builder.mutation<null, T>({
      query: data => ({
        url: this.basePath,
        method: 'POST',
        body: multipart ? CrudController.generateFormData(data) : data,
        params: {
          userType: data.userType,
          tenantId: data.tenantId,
          enterpriseId: data.enterpriseId,
          farmId: data.farmId
        }
      }),
      invalidatesTags: queryTags[this.tag].list(),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          onSuccessQuery(this.title, 'created');
        } catch (__) {}
      }
    });

  updateDocumentMutation = <T extends { id: string }>(multipart = false) =>
    this.builder.mutation<null, T>({
      query: ({ id, ...data }) => ({
        url: `${this.basePath}${id}/`,
        method: 'PATCH',
        body: multipart ? CrudController.generateFormData(data) : data
      }),
      invalidatesTags: queryTags[this.tag].list(),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          onSuccessQuery(this.title, 'updated');
        } catch (__) {}
      }
    });

  getDocumentsQuery = <T>(transformer?: TransformerFunctionType<T>) =>
    this.builder.query<GetAllResponse<T>, Params>({
      query: params => {
        return {
          url: this.basePath,
          params
        };
      },
      transformResponse: (response: GetAllResponse<T>) => {
        if (!transformer) return response;

        const newResults = response.results.map(transformer);
        return { ...response, results: newResults };
      },
      providesTags: queryTags[this.tag].list()
    });

  getDocumentQuery = <T, S = T>(
    transformer?: TransformerFunctionType<T, S>,
    initialParams?: Params
  ) =>
    this.builder.query<S, string>({
      query: id => {
        return {
          url: `${this.basePath}${id}/`,
          params: initialParams
        };
      },
      transformResponse: (response: T) => {
        if (!transformer) return response as unknown as S;

        return transformer(response);
      },
      providesTags: (_, __, arg) => queryTags[this.tag].single(arg)
    });
}

export const { useDeleteDocumentMutation, useGetDocumentsQuery } = baseApi;
