import type { DialogActionsProps } from 'components/DialogActions';
import { UserRole, UserType } from './userInterface';

export type ExtraFields<T> = Partial<Record<keyof T, string>> & { labels?: string };

export type EditFormComponentProps<T> = DialogActionsProps & {
  initialValues?: T;
  extraFields?: ExtraFields<T>;
};

export type ViewComponentProps<T> = {
  data: T;
};

export type LatLngTuple = [number, number];

export type LatLngObject = { lat: number; lng: number };

export interface CommonExtraFields {
  tenantId?: string;
  enterpriseId?: string;
  farmGroupId?: string;
  farmId?: string;
  fieldId?: string;
  cropClassId?: string;
  cropId?: string;
  regionId?: string;
}

export interface Params {
  page?: number;
  limit?: number;
  ordering?: string;
  select?: string;
  search?: string;
  roleId?: UserRole;
  tenantId?: string;
  enterpriseId?: string;
  farmId?: string;
  userType?: UserType;
  [key: string]: unknown;
}

export interface GetAllResponse<T> {
  count: number;
  results: T[];
  next?: string;
  previous?: string;
}

export type Mutable<T> = {
  -readonly [K in keyof T]: Mutable<T[K]>;
};

export enum Pages {
  HOME = '/home',
  LOGIN = '/login',
  UNAUTHORIZED = '/unauthorized',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password/:userId/:token',
  VERIFY_ACCOUNT = '/verify-email/:userId/:token',
  PROFILE = '/profile',
  CROPS = '/crops',
  CROPS_GROWTH_STAGE = '/crops-growth-stage',
  CROP_CLASS = '/crop-class',
  CROP_TYPE = '/crop-type',
  VARIETIES = '/varieties',
  ENTERPRISES = '/enterprises',
  ENTERPRISE_USERS = '/enterprise-users',
  TENANTS = '/tenants',
  TENANT_ADMINS = '/tenant-admins',
  SUPER_USERS = '/super-users',
  FARM_GROUPS = '/farm-groups',
  FARM_USERS = '/farm-users',
  FARMS = '/farms',
  FIELDS = '/fields',
  RECENTLY_UPDATED_FIELDS = '/recently-updated-fields',
  REGIONS = '/regions',
  MODEL_OUTPUTS = '/model-outputs',
  GROWTH_STAGES = '/growth-stages',
  USERS_ACTIVITIES = '/users-activities',
  EMAIL_QUEUE = '/email-queue'
}
