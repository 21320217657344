import { FC } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PreLoadableLink from 'components/PreLoadableLink';
import CustomAvatar from 'components/CustomAvatar';
import { useAppSelector } from 'hooks/useAppSelector';
import { Pages } from 'interfaces/general';
import { useStyles } from './styles/main';

const MainHeader: FC = () => {
  const { classes } = useStyles();
  const { user } = useAppSelector(state => state.auth);

  return (
    <header className={classes.header}>
      <Stack direction="row">
        <Typography variant="h1" mr="auto">
          Mercury
        </Typography>

        {user && (
          <Stack direction="row" spacing={2} alignItems="center">
            <PreLoadableLink to={Pages.PROFILE}>
              <CustomAvatar src={user.avatar} />
            </PreLoadableLink>

            <div>
              <Typography className="medium">
                Logged in as&nbsp;
                <PreLoadableLink to={Pages.PROFILE} className={classes.link}>
                  <strong>{`${user.firstName} ${user.lastName}`}</strong>
                </PreLoadableLink>
              </Typography>
              <Typography className="medium">{user.role}</Typography>
            </div>
          </Stack>
        )}
      </Stack>
    </header>
  );
};

export default MainHeader;
