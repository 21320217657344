import { ComponentType, createElement, lazy, forwardRef, FC, Attributes } from 'react';

type ImportType<T> = () => Promise<{ default: T }>;

export type PreLoadableComponent<T extends ComponentType> = T & {
  preload: () => Promise<T>;
};

export const lazyPreload = <T extends ComponentType>(importStatement: ImportType<T>) => {
  const LazyComponent = lazy(importStatement);
  let importPromise: Promise<T> | undefined;
  let LoadedComponent: T | undefined;

  const Component = forwardRef(function LazyWithPreload(props, ref) {
    return createElement(
      (LoadedComponent ?? LazyComponent) as FC,
      Object.assign(ref ? { ref } : {}, props) as Attributes
    );
  }) as unknown as PreLoadableComponent<T>;

  Component.preload = () => {
    if (!importPromise) {
      importPromise = importStatement().then(module => {
        LoadedComponent = module.default;
        return LoadedComponent;
      });
    }
    return importPromise;
  };

  return Component;
};
