// import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware, PayloadAction } from '@reduxjs/toolkit';

import { showStandAloneAlert } from 'contexts/AlertContext';

interface ActionPayload {
  data: { messages: string[] };
  status: number;
}

const isRejected = ({ payload }: PayloadAction<ActionPayload>): boolean => {
  if (!payload || !payload.status) return false;

  if (payload.status < 200 || payload.status >= 300) {
    return true;
  }

  return false;
};

/**
 * A middleware function used for handling errors
 */
export const rtkQueryErrorHandler: Middleware =
  (_: MiddlewareAPI) => next => (action: PayloadAction<ActionPayload>) => {
    const rejected = isRejected(action);

    if (rejected) {
      showStandAloneAlert({
        status: 'error',
        message: action.payload.data.messages?.[0] || 'Something went wrong.'
      });
    }

    return next(action);
  };
