import { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './styles';

interface DialogTitleProps {
  title: string;
  onClose: VoidFunction;
  className?: string;
}

const DialogTitle: FC<DialogTitleProps> = ({ title, className, onClose }) => {
  const { classes } = useStyles();

  return (
    <div className={`${classes.root} ${className || ''}`}>
      <Typography variant="h5" className="semi-bold">
        {title}
      </Typography>

      <IconButton aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default DialogTitle;
