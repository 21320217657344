import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUser } from 'interfaces/userInterface';
import { Storage } from 'utils/Storage';

interface AuthState {
  user?: IUser;
  refreshToken?: string;
  accessToken?: string;
}

const initialState = {
  refreshToken: Storage.getRefreshToken(),
  accessToken: Storage.getAccessToken(),
  user: Storage.getCurrentUser()
} as AuthState;

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload }: PayloadAction<AuthState>) => {
      if (payload.user) {
        state.user = payload.user;
      }

      if (payload.refreshToken) {
        state.refreshToken = payload.refreshToken;
      }

      if (payload.accessToken) {
        state.accessToken = payload.accessToken;
      }

      Storage.setCredentials(payload.user, payload.refreshToken, payload.accessToken);
    },
    logout: state => {
      state.user = undefined;
      state.refreshToken = undefined;
      state.accessToken = undefined;
      Storage.removeCredentials();
    }
  }
});

export const { setCredentials, logout } = AuthSlice.actions;

export default AuthSlice.reducer;
