import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import AppleIcon from '@mui/icons-material/Apple';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import BusinessIcon from '@mui/icons-material/Business';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupIcon from '@mui/icons-material/Group';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AppsIcon from '@mui/icons-material/Apps';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import BentoIcon from '@mui/icons-material/Bento';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import TimelineIcon from '@mui/icons-material/Timeline';
import EmailIcon from '@mui/icons-material/Email';
import ArticleIcon from '@mui/icons-material/Article';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import CompassCalibrationIcon from '@mui/icons-material/CompassCalibration';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { Pages } from 'interfaces/general';
import { UserRole } from 'interfaces/userInterface';
import { lazyPreload } from 'utils/lazyPreload';
import type { AppRoutesType, IAppRoute } from 'interfaces/routeInterface';

const HomePage = lazyPreload(() => import('pages/home'));
const CropsPage = lazyPreload(() => import('pages/crops'));
const CropsGrowthStagePage = lazyPreload(() => import('pages/crops-growth-stage'));
const CropClassPage = lazyPreload(() => import('pages/crop-class'));
const CropTypePage = lazyPreload(() => import('pages/crop-type'));
const EnterprisesPage = lazyPreload(() => import('pages/enterprises'));
const EnterpriseUsersPage = lazyPreload(() => import('pages/enterprise-users'));
const TenantsPage = lazyPreload(() => import('pages/tenants'));
const TenantAdminsPage = lazyPreload(() => import('pages/tenant-admins'));
const SuperUsersPage = lazyPreload(() => import('pages/super-users'));
const FarmGroupsPage = lazyPreload(() => import('pages/farm-groups'));
const FarmsPage = lazyPreload(() => import('pages/farms'));
const FieldsPage = lazyPreload(() => import('pages/fields'));
const RecentlyUpdatedFieldsPage = lazyPreload(() => import('pages/recently-updated-fields'));
const RegionsPage = lazyPreload(() => import('pages/regions'));
const VarietiesPage = lazyPreload(() => import('pages/varieties'));
const FarmUsersPage = lazyPreload(() => import('pages/farm-users'));
const ModelOutputsPage = lazyPreload(() => import('pages/model-outputs'));
const GrowthStagesPage = lazyPreload(() => import('pages/growth-stages'));
const UserActivityPage = lazyPreload(() => import('pages/users-activities'));
const EmailQueuePage = lazyPreload(() => import('pages/email-queue'));
const UserProfilePage = lazyPreload(() => import('pages/profile'));

type RoutesType = IAppRoute & {
  children?: IAppRoute[];
};

export const ROUTES: RoutesType[] = [
  // HOME
  {
    path: Pages.HOME,
    component: HomePage,
    label: 'Home',
    icon: HomeIcon
  },
  // User management
  {
    label: 'User Management',
    icon: PeopleAltIcon,
    allowedRoles: [UserRole['Super User']],
    children: [
      {
        path: Pages.SUPER_USERS,
        component: SuperUsersPage,
        label: 'Super Users',
        icon: AdminPanelSettingsIcon
      }
    ]
  },
  // Tenant management
  {
    label: 'Tenant Management',
    icon: SupervisedUserCircleIcon,
    allowedRoles: [UserRole['Super User'], UserRole.Tenant],
    children: [
      {
        path: Pages.TENANTS,
        component: TenantsPage,
        label: 'Tenants',
        icon: PeopleOutlineIcon,
        allowedRoles: [UserRole['Super User']]
      },
      {
        path: Pages.TENANT_ADMINS,
        component: TenantAdminsPage,
        label: 'Tenant Admins',
        icon: SupervisorAccountIcon
      }
    ]
  },
  // Enterprise management
  {
    label: 'Enterprise Management',
    icon: BusinessIcon,
    allowedRoles: [UserRole['Super User'], UserRole.Tenant, UserRole.Enterprise],
    children: [
      {
        path: Pages.ENTERPRISES,
        component: EnterprisesPage,
        label: 'Enterprises',
        icon: ApartmentIcon,
        allowedRoles: [UserRole['Super User'], UserRole.Tenant]
      },
      {
        path: Pages.ENTERPRISE_USERS,
        component: EnterpriseUsersPage,
        label: 'Users',
        icon: GroupIcon
      }
    ]
  },
  // Farm management
  {
    label: 'Farm Management',
    icon: AgricultureIcon,
    allowedRoles: [UserRole['Super User'], UserRole.Tenant, UserRole.Enterprise, UserRole.Farm],
    children: [
      {
        path: Pages.FARM_GROUPS,
        component: FarmGroupsPage,
        label: 'Farm Groups',
        icon: WorkspacesIcon,
        allowedRoles: [UserRole['Super User'], UserRole.Tenant, UserRole.Enterprise]
      },
      {
        path: Pages.FARMS,
        component: FarmsPage,
        label: 'Farms',
        icon: AppsIcon,
        allowedRoles: [UserRole['Super User'], UserRole.Tenant, UserRole.Enterprise]
      },
      {
        label: 'Field Management',
        icon: DeveloperBoardIcon,
        children: [
          {
            path: Pages.FIELDS,
            component: FieldsPage,
            label: 'Fields',
            icon: AutoAwesomeMosaicIcon
          },
          // Model outputs
          {
            path: Pages.MODEL_OUTPUTS,
            component: ModelOutputsPage,
            label: 'Model outputs',
            icon: AcUnitIcon
          },
          {
            path: Pages.CROPS,
            component: CropsPage,
            label: 'Crops',
            icon: BentoIcon,
            allowedRoles: [UserRole['Super User']]
          },
          {
            path: Pages.CROPS_GROWTH_STAGE,
            component: CropsGrowthStagePage,
            label: 'Crops Growth Stage',
            icon: BlurLinearIcon,
            allowedRoles: [UserRole['Super User']]
          }
        ]
      },

      {
        path: Pages.FARM_USERS,
        component: FarmUsersPage,
        label: 'Farm Users',
        icon: GroupIcon
      }
    ]
  },
  // Crops management
  {
    label: 'Crops Management',
    icon: CategoryIcon,
    allowedRoles: [UserRole['Super User']],
    children: [
      {
        path: Pages.CROP_CLASS,
        component: CropClassPage,
        label: 'Crop Class',
        icon: FingerprintIcon
      },
      {
        path: Pages.CROP_TYPE,
        component: CropTypePage,
        label: 'Crop Type',
        icon: AppleIcon
      }
    ]
  },
  {
    label: 'Reference Data',
    icon: CompassCalibrationIcon,
    allowedRoles: [UserRole['Super User']],
    children: [
      {
        path: Pages.VARIETIES,
        component: VarietiesPage,
        label: 'Varieties',
        icon: AutoAwesomeIcon
      },
      // Regions
      {
        path: Pages.REGIONS,
        component: RegionsPage,
        label: 'Regions',
        icon: CalendarViewMonthIcon
      },
      // Growth stages
      {
        path: Pages.GROWTH_STAGES,
        component: GrowthStagesPage,
        label: 'Growth Stages',
        icon: TimelineIcon
      }
    ]
  },
  // Logs management
  {
    label: 'Logs Management',
    icon: ArticleIcon,
    allowedRoles: [UserRole['Super User']],
    children: [
      {
        path: Pages.USERS_ACTIVITIES,
        component: UserActivityPage,
        label: 'User Activity',
        icon: ManageAccountsIcon
      },
      {
        path: Pages.EMAIL_QUEUE,
        component: EmailQueuePage,
        label: 'Email Queue',
        icon: EmailIcon
      }
    ]
  },
  // Updated Fields (Super Users)
  {
    path: Pages.RECENTLY_UPDATED_FIELDS,
    component: RecentlyUpdatedFieldsPage,
    label: 'Recently Updated Fields',
    allowedRoles: [UserRole['Super User']],
    icon: AutoAwesomeMosaicIcon
  }
];

const flatRoutes = (routes: RoutesType[]): IAppRoute[] =>
  routes.flatMap(route => (route.children ? flatRoutes(route.children) : [route]));

export const APP_ROUTES = (flatRoutes(ROUTES) as AppRoutesType[]).concat({
  path: Pages.PROFILE,
  component: UserProfilePage
});
