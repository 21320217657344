import { FC, PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Pages } from 'interfaces/general';
import { preloadComponent } from 'utils/preload';

interface PreLoadableLinkProps extends PropsWithChildren {
  to: Pages;
}

const PreLoadableLink: FC<PreLoadableLinkProps & LinkProps> = ({ to, children, ...rest }) => {
  return (
    <Link to={to} onMouseEnter={() => preloadComponent(to)} {...rest}>
      {children}
    </Link>
  );
};

export default PreLoadableLink;
