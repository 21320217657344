import { FC } from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';

interface PersonAvatarProps extends AvatarProps {
  usePrefix?: boolean;
}

const CustomAvatar: FC<PersonAvatarProps> = ({ usePrefix = true, src, ...rest }) => {
  const imgSrc = usePrefix && src ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${src}` : src;

  return <Avatar src={imgSrc} {...rest} />;
};

export default CustomAvatar;
