import { FC, PropsWithChildren } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';

import { Color, theme } from 'theme/muiTheme';

const MaterialProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          '*': {
            margin: 0,
            padding: 0,
            boxSizing: 'inherit'
          },
          html: {
            fontSize: '62.5%',
            overflowY: 'auto',
            overflowX: 'hidden',
            scrollBehavior: 'smooth',

            [theme.breakpoints.up('bigDesktop')]: {
              fontSize: '75%'
            },

            [theme.breakpoints.down('desktop')]: {
              fontSize: '56.25%'
            }
          },
          body: {
            boxSizing: 'border-box',
            fontSize: '1.5rem',
            fontFamily: 'Montserrat',
            color: Color.DARK,
            padding: '0 !important',
            fontWeight: 500,
            lineHeight: 1.5
          },
          time: {
            fontSize: '1.2rem'
          },
          a: {
            textDecoration: 'none',
            display: 'block'
          },
          textarea: {
            '&.MuiInputBase-input': {
              lineHeight: 1.4
            }
          },
          u: {
            textDecorationThickness: 3,
            textUnderlineOffset: 3
          },
          figure: {
            position: 'relative'
          },

          '[class$=MuiStack-root] > .MuiFormControl-root:first-of-type': {
            marginBottom: 0
          },

          '.u-mb-z': {
            marginBottom: '0 !important'
          },

          '.map-container': {
            transform: 'rotate(0deg)',
            width: '100%',
            height: '80%',

            '&.dw': {
              marginTop: '5rem'
            },

            '& .fixed-cta': {
              position: 'fixed',
              bottom: '-5rem',
              right: '2.4rem'
            }
          },

          '.react-datepicker': {
            fontSize: '1.4rem',
            fontFamily: 'Montserrat'
          },

          '.react-datepicker__current-month,.react-datepicker-time__header,.react-datepicker-year-header':
            {
              fontSize: '1.4rem'
            },

          '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list':
            {
              height: 'calc(195px + (3rem / 2))'
            },

          '.react-datepicker__week-number': {
            width: '3rem',
            lineHeight: '3rem'
          },

          '.react-datepicker__day-name,.react-datepicker__day,.react-datepicker__time-name': {
            width: '3rem',
            lineHeight: '3rem'
          },

          '.react-datepicker-popper': {
            zIndex: 9999
          }
        }}
      />

      {children}
    </ThemeProvider>
  );
};

export default MaterialProvider;
