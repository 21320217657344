import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

interface SidebarListItemProps {
  label: string;
  path?: string;
  icon: React.ElementType;
  sidebarExpanded: boolean;
  nested?: boolean;
  pl?: number;
  onClick?: VoidFunction;
}

const SidebarListItem: FC<SidebarListItemProps> = ({
  label,
  path,
  icon: Icon,
  sidebarExpanded,
  nested,
  onClick,
  pl
}) => {
  const { pathname } = useLocation();

  return (
    <ListItem disablePadding className={pathname === path ? 'active' : ''}>
      <ListItemButton
        sx={{
          justifyContent: sidebarExpanded ? 'initial' : 'center'
        }}
        {...(path ? { to: path, component: Link } : {})}
        onClick={onClick}
      >
        <ListItemIcon
          sx={{
            mr: sidebarExpanded ? 3 : 'auto',
            justifyContent: 'center',
            pl: nested && sidebarExpanded ? pl || 2 : 'initial'
          }}
        >
          <Icon />
        </ListItemIcon>
        <ListItemText primary={label} sx={{ opacity: sidebarExpanded ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
};

export default SidebarListItem;
