export enum UserRole {
  'Super User' = 1,
  Tenant = 2,
  Enterprise = 3,
  Farm = 4
}

export type UserRoleKeys = keyof typeof UserRole;

export enum UserType {
  ENTERPRISE = 'Enterprise',
  TENANT = 'Tenant',
  FARM = 'Farm',
  SUPER_USER = 'Super User'
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  role: UserRoleKeys;
  createdAt: string;
  avatar?: string;
  lastLogin?: string;
  suspend?: boolean;
  tenantId?: string;
  enterpriseId?: string;
  farmId?: string;
  subscriptionLevel?: number;
}

export type UserFormType = Omit<IUser, 'lastLogin' | 'createdAt' | 'role'> & {
  roleId?: UserRole;
  password?: string;
};

export type FarmUserType = { userId: string; farmId: string };

export interface IUserActivity {
  id: string;
  user: Pick<IUser, 'id' | 'firstName' | 'lastName' | 'username'> & { name?: string };
  verb: string;
  document: {
    name: string;
    id: string;
    value?: string;
  };
  createdAt: string;
}
