import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FullScreenLoader: FC = () => {
  return (
    <Box
      mt={20}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <CircularProgress size={50} thickness={2} />
      <Typography mt={3}>Loading ...</Typography>
    </Box>
  );
};

export default FullScreenLoader;
