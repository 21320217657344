import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Pages } from 'interfaces/general';
import { UserRole } from 'interfaces/userInterface';
import { useAppSelector } from 'hooks/useAppSelector';

interface ProtectRouteProps {
  to?: Pages;
  allowedRoles?: UserRole[];
}

const ProtectRoute: FC<ProtectRouteProps> = ({ to, allowedRoles }) => {
  const location = useLocation();
  const { user } = useAppSelector(state => state.auth);

  if (!user && !to) {
    return <Navigate to={Pages.LOGIN} state={{ from: location }} replace />;
  }

  if (to && user) {
    return <Navigate to={to} state={{ from: location }} replace />;
  }

  if (user && allowedRoles) {
    return allowedRoles.includes(UserRole[user.role]) ? (
      <Outlet />
    ) : (
      <Navigate to={Pages.UNAUTHORIZED} state={{ from: location }} replace />
    );
  }

  return <Outlet />;
};

export default ProtectRoute;
