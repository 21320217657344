import { FC } from 'react';
import { matchPath } from 'react-router-dom';

import type { Pages } from 'interfaces/general';
import type { AppRoutesType, ComponentType } from 'interfaces/routeInterface';
import type { PreLoadableComponent } from './lazyPreload';
import { APP_ROUTES } from '../routes';

const isPreLoadable = (cmp: ComponentType): cmp is PreLoadableComponent<FC> => 'preload' in cmp;

export const findComponentForPreload = (
  path: Pages,
  routes: AppRoutesType[]
): ComponentType | null => routes.find(r => matchPath({ path }, r.path))?.component || null;

export const preloadComponent = (path: Pages) => {
  const component = findComponentForPreload(path, APP_ROUTES);
  if (component && isPreLoadable(component)) component.preload();
};
