import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import 'react-datepicker/dist/react-datepicker.css';

import MaterialUIProvider from 'contexts/MuiContext';
import MapLoaderProvider from 'contexts/MapContext';
import { store } from './redux/store';
import App from './App';

const muiCache = createCache({
  key: 'mui',
  prepend: true
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <CacheProvider value={muiCache}>
      <MapLoaderProvider>
        <MaterialUIProvider>
          <App />
        </MaterialUIProvider>
      </MapLoaderProvider>
    </CacheProvider>
  </Provider>
);
