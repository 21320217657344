import Cookies, { CookieAttributes } from 'js-cookie';

import { IUser } from 'interfaces/userInterface';

const DEFAULT_SET_OPTIONS: CookieAttributes = {
  path: '/',
  expires: 365,
  sameSite: 'Strict',
  secure: process.env.NODE_ENV === 'production'
};

export enum CookieKey {
  USER = 'user',
  REFRESH_TOKEN = 'refreshToken',
  ACCESS_TOKEN = 'accessToken'
}

export class Storage {
  static deleteCookie = (key: CookieKey) => {
    Cookies.remove(key, { path: '/' });
  };

  static setCookie = (key: CookieKey, data: unknown) => {
    Cookies.set(key, JSON.stringify(data), DEFAULT_SET_OPTIONS);
  };

  static getCookie = <T>(key: CookieKey): T | undefined => {
    const data = Cookies.get(key);

    return data ? (JSON.parse(data) as T) : undefined;
  };

  static setCredentials = (user?: IUser, refreshToken?: string, accessToken?: string) => {
    if (refreshToken) {
      Storage.setCookie(CookieKey.REFRESH_TOKEN, refreshToken);
    }

    if (accessToken) {
      Storage.setCookie(CookieKey.ACCESS_TOKEN, accessToken);
    }

    if (user) {
      Storage.setCookie(CookieKey.USER, user);
    }
  };

  static removeCredentials = () => {
    Storage.deleteCookie(CookieKey.REFRESH_TOKEN);
    Storage.deleteCookie(CookieKey.ACCESS_TOKEN);
    Storage.deleteCookie(CookieKey.USER);
  };

  static getRefreshToken = () => Storage.getCookie<string>(CookieKey.REFRESH_TOKEN);

  static getAccessToken = () => Storage.getCookie<string>(CookieKey.ACCESS_TOKEN);

  static getCurrentUser = () => Storage.getCookie<IUser>(CookieKey.USER);
}
